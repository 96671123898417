import React, { useEffect, useState } from "react";
import PhoneDisplayPanel from "../components/PhoneDisplayPanel";
import { Navigate, useNavigate } from "react-router-dom";

export default function MainLandingPage() {
	const navigate = useNavigate();
	useEffect(() => {
		navigate("/asongus");
	}, []);
}

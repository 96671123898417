import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const SongInfo = ({ song, artist, uri }) => {
	const titleStyle = {
		fontWeight: "600",
		fontSize: "24px",
		textDecoration: "none",
		marginBottom: 0,
		width: "100%",
		marginTop: 0,
		animation: "underlineAnimation 1.5s forwards",
	};

	const artistStyle = {
		textAlign: "left",
		color: "lightGrey",
		width: "100%",
		marginTop: 5,
		marginBottom: 0,
	};

	const containerStyle = {
		flex: 1,
	};

	function spotifyUriToLink(uri) {
		if (uri) {
			const baseUrl = "https://open.spotify.com/";
			const [type, id] = uri.split(":").slice(1);
			return `${baseUrl}${type}/${id}`;
		} else {
			return "";
		}
	}

	return (
		<div style={containerStyle}>
			<style>
				{`
          @keyframes underlineAnimation {
  0% {
    text-decoration: none;
  }
  50% {
    text-decoration: none;
  }
  100% {
    text-decoration: underline;
  }
}
        `}
			</style>
			{uri ? (
				<a
					target={"_blank"}
					href={spotifyUriToLink(uri)}
					style={titleStyle}
					className={"text"}
				>
					{song}
				</a>
			) : (
				<h2 style={titleStyle} className={"text"}>
					{song}
				</h2>
			)}

			<p style={artistStyle} className={"text"}>
				{artist}
			</p>
		</div>
	);
};

const SongDisplayPanel = ({
	uri,
	image,
	song,
	artist,
	handleSubmit,
	selectedItems,
	voteSubmitted,
}) => {
	const imageStyle = {
		flex: 1,
		borderRadius: "5%", // This sets the border-radius to create rounded corners
		width: "100%", // Set the maximum width to 100%
		// margin: "15px",
		// height: "100%",
	};

	const containerStyle = {
		alignItems: "center",
		display: "flex",
		justifyContent: "center",
		flexDirection: "column",
		// paddingInline: "15px",
		padding: "10px",
		paddingTop: "20px",
		paddingBottom: "12px",
		borderRadius: "10px",
		width: "92%",

		background: "#222222",
	};

	//todo gradient

	// Determine whether the button should be disabled
	const isButtonDisabled = selectedItems.length === 0 || voteSubmitted;

	// Apply a grayed-out style to the button when it's disabled
	const buttonStyle = {
		// width: '50%',
		// marginTop: '10px',
		// height: '200%',
		paddingLeft: "20px",
		paddingRight: "20px",
		minHeight: "50px",
		background: !isButtonDisabled ? "lightgray" : "#222222",
		color: "#222222",
		borderWidth: "0px",
		borderRadius: "10px",
		fontWeight: "bold",
		opacity: !isButtonDisabled ? "1" : "0",
		// alignSelf: 'bottom',
	};

	const submittedStyle = {
		...buttonStyle,
		opacity: "0",
	};

	return (
		<div style={containerStyle}>
			<div style={{}}>
				<img src={image} alt="Album Cover" style={imageStyle} />
			</div>
			<div
				style={{
					display: "flex",
					width: "100%",
					alignItems: "center",
					marginTop: "10px",
					// paddingLeft: "15px",
				}}
			>
				<SongInfo uri={uri} song={song} artist={artist} />
				<button
					onClick={handleSubmit}
					style={voteSubmitted ? submittedStyle : buttonStyle}
					disabled={isButtonDisabled} // Disable the button based on the condition
				>
					GUESS
				</button>
			</div>
		</div>
	);
};

export default SongDisplayPanel;

import React from "react";

const PhoneDisplayPanel = ({
	children,
	width = "100%",
	backgroundColor = "#111111",
}) => {
	const containerStyle = {
		overflow: "hidden",
		// position: "relative",
		// backgroundColor: "green",

		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "flex-start",
		minHeight: "100vh",
		flex: "1", // Allow the container to grow based on content
		backgroundColor: backgroundColor,
		// marginTop: "-200px",
		// background: "green",
	};

	const centralPanelStyle = {
		height: "100vh",
		position: "relative",
		width: width,
		display: "flex",
		maxWidth: "380px",
		flexDirection: "column",
		justifyContent: "flex-start",
		flex: "1", // Allow the container to grow based on content
		alignItems: "center",
		paddingBottom: "60px",
	};

	return (
		<>
			<div style={containerStyle}>
				<div style={centralPanelStyle}>{children}</div>
			</div>
			{/* <div style={{ color: "white" }}>Leave</div> */}
		</>
	);
};

export default PhoneDisplayPanel;

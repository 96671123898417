import React, { useEffect, useState } from "react";
import styles from "../../styles/MainMenu.module.css";
import { useSpotifyAuth } from "../../services/SpotifyAuth";
import { useLocation, useNavigate } from "react-router-dom";
import { serverIp, reactPort } from "../../config";
import InputField from "./InputField";

const EnterName = ({ modeRoute = "asongus" }) => {
	// If not signed in, go back to main menu
	const landingPage = process.env.REACT_APP_FRONTEND_URL + "/enter-name/";
	//todo wtf
	const { token, loginUrl, logout } = useSpotifyAuth(landingPage);

	const [name, setName] = useState(window.localStorage.getItem("name") || "");
	const pin = window.localStorage.getItem("currentGamePin");

	//Animation triggers
	const [shake, setShake] = useState(false);

	const navigate = useNavigate();

	const useQuery = () => {
		return new URLSearchParams(useLocation().search);
	};

	const query = useQuery();

	const handleNameChange = (event) => {
		setName(event.target.value);
		window.localStorage.setItem("name", event.target.value);
	};

	const shakeTextField = () => {
		setShake(true);
		setTimeout(() => {
			setShake(false);
		}, 500);
	};

	const handleJoinGame = () => {
		const goToLobby = query.get("goToLobby");

		if (!name) {
			shakeTextField();
		} else if (goToLobby) {
			if (goToLobby) {
				navigate("/" + modeRoute + "/lobby/" + goToLobby);
			}
		} else {
			navigate(`/${modeRoute}/lobby/${pin}`);
		}
	};

	return (
		<div className={styles.container}>
			<div className={styles.centralPanel}>
				<InputField
					placeholder={"Your name"}
					shake={shake}
					value={name}
					onNameChange={handleNameChange}
				/>
				<button
					onClick={handleJoinGame}
					className={`${styles.join} ${styles.button}`}
				>
					JOIN GAME
				</button>
			</div>
		</div>
	);
};

export default EnterName;

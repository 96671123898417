import React, { useState } from "react";

const ContactButton = () => {
	const [copied, setCopied] = useState(false);

	// const handleCopyLink = () => {
	// 	const url = window.location.href;
	// 	if (navigator.clipboard && navigator.clipboard.writeText) {
	// 		navigator.clipboard.writeText(url).then(
	// 			() => {
	// 				setCopied(true);
	// 				setTimeout(() => setCopied(false), 2000); // Reset the copied state after 2 seconds
	// 			},
	// 			() => {
	// 				console.error("Failed to copy the link.");
	// 			}
	// 		);
	// 	} else {
	// 		console.error(
	// 			"Clipboard API not supported or not available in this context."
	// 		);
	// 	}
	// };

	return (
		<div>
			<a
				target="_blank"
				href="mailto:zspmailbox@gmail.com?subject=Asongus%20Inquiry"
				style={{
					textDecoration: "none",
					color: "gray",
					border: "none",
					background: "none",
					fontSize: "14px",
					cursor: "pointer",
				}}
			>
				Contact Me
			</a>
			{copied && (
				<div style={{ background: "white", color: "black", position: "fixed" }}>
					Game link copied!
				</div>
			)}
		</div>
	);
};

export default ContactButton;

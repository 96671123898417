import React, { useEffect, useState } from "react";
import styles from "./NameButton.module.css";

const NameButton = ({
	name,
	handleChange,
	allPlayersHaveVoted,
	voteSubmitted,
	isChecked,
	correctNames,
	style,
}) => {
	const [buttonClass, setButtonClass] = useState("unchecked");

	useEffect(() => {
		if (voteSubmitted) {
			if (isChecked) {
				setButtonClass("checkedSubmitted");
			} else {
				setButtonClass("uncheckedSubmitted");
			}
		} else {
			if (isChecked) {
				setButtonClass("checked");
			} else {
				setButtonClass("unchecked");
			}
		}

		if (correctNames) {
			const isThisACorrectAnswer = correctNames.includes(name);
			if (isChecked && isThisACorrectAnswer) {
				setButtonClass("correct");
			} else if (isChecked && !isThisACorrectAnswer) {
				setButtonClass("incorrect");

				setTimeout(() => {
					setButtonClass("uncheckedSubmitted");
				}, 1000);
			} else if (isThisACorrectAnswer) {
				setTimeout(() => {
					setButtonClass("missed");
				}, 1000);
			}
		}
	}, [name, isChecked, correctNames, voteSubmitted]);

	const checkboxStyle = {
		display: "none", // Hide the default checkbox input
		// transition: "backgroundColor 1s",
	};

	const handleChangeWrapper = () => {
		if (!voteSubmitted) {
			handleChange(name); // Call handleChange only if voteSubmitted is false
		}
	};

	const [touchHandled, setTouchHandled] = useState(false);

	function handleMouseDown() {
		if (!touchHandled) {
			handleChangeWrapper();
		}
	}

	function handleTouch() {
		// handleChangeWrapper();
		// setTouchHandled(true);
		// setTimeout(() => setTouchHandled(false), 500);
	}

	return (
		<div
			className={
				(name === "#Ad" &&
					!isChecked &&
					correctNames &&
					!correctNames.includes("#Ad")) ||
				(!correctNames && !isChecked && name === "#Ad")
					? styles.hiddenAdButton
					: styles.commonButton + " " + styles[buttonClass]
			}
			style={style}
			onMouseDown={handleMouseDown}
			onTouchStart={handleTouch}
		>
			{/* <input
				type="checkbox"
				style={checkboxStyle}
				onChange={handleChangeWrapper}
				checked={isChecked}
			/> */}
			<span>{name}</span>
		</div>
	);
};

export default NameButton;

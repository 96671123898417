// src/components/SpotifyPlayer.js
import React, { useEffect, useState, useRef } from "react";

const SpotifyPlayer = ({ uri, showPlayButton, transitionOut }) => {
	const [opacity, setOpacity] = useState(0.9);
	const [isChrome, setIsChrome] = useState(false);
	const [isDesktop, setIsDesktop] = useState(false);

	useEffect(() => {
		if (showPlayButton) {
			setOpacity(0.9);
		} else {
			setOpacity(0);
		}
	}, [showPlayButton]);

	//make a new one every time the uri changes
	function getTrackIdFromUri(uri) {
		// Split the URI by ':' and get the last part
		const parts = uri.split(":");
		if (parts.length === 3 && parts[0] === "spotify" && parts[1] === "track") {
			return parts[2];
		}
		// Return null or handle the error if the URI is not in the expected format
		return null;
	}

	return (
		<div
			style={{ position: "relative", zIndex: "2" }}
			className={transitionOut ? "fade-out" : "fade-in2"}
		>
			<style>
				{`
          @keyframes fadeIn {
            0% { opacity: 0; }
            50% { opacity: 0; }
            100% { opacity: 0.9; }
          }
        `}
			</style>
			<div
				style={{
					position: "relative",
					top: "360px",
					left: "144px",
					width: "56px",
					height: "56px",
					overflow: "hidden",
					borderRadius: "10px",
					animation: opacity === 0.9 ? "fadeIn 1s" : "",
					zIndex: "1",
					opacity: opacity,
					// borderRadius: "28px",
				}}
			>
				<div
					style={{
						borderRadius: "12px",
						position: "absolute",
						top: "-280px",
						left: "-322px",
						width: "300px",
					}}
				>
					<iframe
						id="spotify-embed"
						style={{ borderRadius: "12px" }}
						src={`https://open.spotify.com/embed/track/${getTrackIdFromUri(
							uri
						)}?utm_source=generator&theme=1`}
						width="400"
						height="400"
						allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
						loading="lazy"
					></iframe>
				</div>
			</div>

			{/* <button id="play-button">{count}</button> */}
		</div>
	);
};

export default SpotifyPlayer;

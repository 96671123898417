import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import { useSpotifyAuth } from "../../services/SpotifyAuth";
import { useNavigate, useParams } from "react-router-dom";
import styles from "../../styles/MainMenu.module.css";
import PhoneDisplayPanel from "../../components/PhoneDisplayPanel";
import {
	fetchNSongs,
	getWhichSongIndicesAreLiked,
	playSpecificSongOnDevice,
	stopPlayback,
} from "../../services/SpotifyApi";

import GuessSongScreen from "../GuessSong/GuessSongScreen";
import Lobby from "./Lobby";
import CurrentScoreScreen from "./CurrentScoreScreen";
import DevicesDropdown from "./DevicesDropdown";
// import SongPreviewPlayButton from "../../components/SongPreviewPlayButton";
import SpotifyPlayer from "../../components/SpotifyPlayer";
import ShareLink from "./ShareLink";

const GamePage = ({ modeRoute, requiresLogin }) => {
	const { lobbyId } = useParams();
	window.localStorage.setItem("currentGamePin", lobbyId);
	const name = window.localStorage.getItem("name");

	const enterNameRoute =
		process.env.REACT_APP_FRONTEND_URL + "/" + modeRoute + "/enter-name/";
	const {
		token,
		loggedInWithPlaylist,
		librarySize,
		userId: spotifyId,
	} = useSpotifyAuth(enterNameRoute);
	const [spotifyDevice, setSpotifyDevice] = useState(null);

	const [socket, setSocket] = useState(null);
	const navigate = useNavigate();

	const [gameData, setGameData] = useState(null);

	const [currentScreen, setCurrentScreen] = useState("");
	const [currentSong, setCurrentSong] = useState(null);

	const [correctAnswer, setCorrectAnswer] = useState(null);
	const [currentRound, setCurrentRound] = useState(-1);
	const [numRounds, setNumRounds] = useState(0);

	const [scoreboard, setScoreboard] = useState(null);
	const [players, setPlayers] = useState([]);

	const [isGameLeader, setIsGameLeader] = useState(false);
	const [readyToStart, setReadyToStart] = useState(false);

	const [transitioning, setTransitioning] = useState(false);

	const [options, setOptions] = useState([]);

	useEffect(() => {
		if (currentScreen === "lobby") {
			window.scrollTo({
				top: 30,
			});
		} else {
			window.scrollTo({
				top: 50,
			});
		}
	}, [currentScreen]);

	useEffect(() => {
		if (requiresLogin && !token && !loggedInWithPlaylist) {
			const url = lobbyId ? `/${modeRoute}/?goToLobby=${lobbyId}` : "/asongus";
			navigate(url);
		}
	}, [navigate, loggedInWithPlaylist, lobbyId, token]);

	useEffect(() => {
		if ((token || loggedInWithPlaylist || !requiresLogin) && name) {
			const newSocket = io(process.env.REACT_APP_BACKEND_URL);
			setSocket(newSocket);
			newSocket.emit(
				"joinGame",
				lobbyId,
				name,
				requiresLogin ? spotifyId : name
			);

			return () => {
				newSocket.disconnect();
			};
		} else if ((token || loggedInWithPlaylist || !requiresLogin) && !name) {
			navigate("/" + modeRoute + "/enter-name");
		}
	}, [navigate, loggedInWithPlaylist, lobbyId, name, token]);

	useEffect(() => {
		async function submitSongs() {
			if (
				socket &&
				(token || loggedInWithPlaylist) &&
				librarySize &&
				librarySize > 0
			) {
				socket.on("requestingSongs", async (amount) => {
					// const songs = [];
					console.log("songs being requested");
					const songs = await fetchNSongs(
						token,
						loggedInWithPlaylist,
						librarySize,
						amount
					);

					socket.emit("submitSongs", lobbyId, songs);
				});

				socket.on("doYouHaveTheseLiked", async (songUris) => {
					const liked_indices = await getWhichSongIndicesAreLiked(
						token,
						songUris
					);

					socket.emit("sendBackLikedIndices", lobbyId, liked_indices);
				});
			}
		}

		submitSongs();
	}, [token, loggedInWithPlaylist, socket, librarySize, lobbyId]);

	useEffect(() => {
		function updateGameHost(players) {
			if (players && players.length > 0) {
				const first_player_id = players[0].socketId;
				setIsGameLeader(socket.id === first_player_id);
			}
		}

		if (socket) {
			socket.on("refresh", () => {
				window.location.reload();
			});
			socket.on("firstSong", (song) => {
				setCurrentSong(song);
				// setCurrentSong(song);
			});

			socket.on("checkConnection", () => {
				socket.emit("isConnected", lobbyId);
			});

			socket.on("updateGameState", (data) => {
				// todo refreshing fucks some stuff. server side

				const parsed_data = JSON.parse(data);

				setGameData(parsed_data);
				setPlayers(parsed_data.players.map((player) => player.playerName));
				setOptions(parsed_data.options);
				console.log("options updateGameState: " + parsed_data.options);
				updateGameHost(parsed_data.players);
				setCurrentRound(parsed_data.currentRound);
				setReadyToStart(parsed_data.readyToStart);
				setScoreboard(parsed_data.scoreboard);
				setNumRounds(parsed_data.numRounds);
				if (parsed_data.currentSongData) {
					setCorrectAnswer(parsed_data.currentSongData.playersWhoHaveSongLiked);
					setCurrentSong(parsed_data.currentSongData.song);
				}
				setTransitioning(false);
				setCurrentScreen(parsed_data.currentScreen);
			});

			socket.on("invalidPlayer", () => {
				socket.emit(
					"joinGame",
					lobbyId,
					name,
					requiresLogin ? spotifyId : name
				);
			});

			socket.on("changeYourName", () => {
				window.alert(
					"There is already a player in that lobby with your name. Please change it"
				);
				navigate("/" + modeRoute + "/enter-name");
				console.log("CHANGE NAME");
			});

			socket.on("gameNotFound", () => {
				window.alert("Could not find that lobby");
				navigate("/" + modeRoute);
			});

			socket.on(
				"newRoundStarted",
				(guessOptions, currentSong, roundNumber, numRounds) => {
					setOptions(guessOptions);
					setNumRounds(numRounds);

					setCorrectAnswer(currentSong.playersWhoHaveSongLiked);
					setCurrentSong(currentSong.song);
					// setCurrentScreen("guess");
					setTransitioning(true);
					setTimeout(() => {
						setCurrentRound(roundNumber);
						setCurrentScreen("guess");
						setTransitioning(false);
					}, 500);
				}
			);

			socket.on("showScoreboard", (scoreboard) => {
				setScoreboard(scoreboard);
				setTransitioning(true);
				setTimeout(() => {
					setCurrentScreen("scoreboard");
					setTransitioning(false);
				}, 500);
			});

			socket.on("gameAlreadyStarted", () => {
				navigate("/" + modeRoute);
				window.alert("The game has already started!");
			});

			return () => {
				socket.off("gameCreated");
			};
		}
	}, [navigate, setCurrentSong, currentSong, spotifyDevice, socket]);

	useEffect(() => {
		if (token && currentSong && spotifyDevice) {
			console.log(spotifyDevice.id);
			playSpecificSongOnDevice(
				token,
				currentSong.uri,
				spotifyDevice.id,
				30 * 1000
			);
		}
	}, [token, currentSong, spotifyDevice]);

	const handleStartGame = () => {
		//await get game state.
		try {
			socket.emit("startGame", lobbyId);
		} catch (error) {
			console.log("couldn't connect " + error.toString());
		}
	};

	const goNext = () => {
		socket.emit("goNext", lobbyId);
	};

	//TODO: somethung can break when fetching library length size in the spotify auth code.
	//need to make that robust and test if it fails to fetch songs

	const handleDeviceChange = (selectedDevice) => {
		if (selectedDevice.id !== "-1" && selectedDevice.id !== "-2") {
			setSpotifyDevice(selectedDevice);
			// window.localStorage.setItem("spotifyDeviceId", selectedDevice.id);
		} else {
			if (spotifyDevice) {
				try {
					stopPlayback(token, spotifyDevice.id);
				} catch (error) {}
			}
			setSpotifyDevice(null);
		}
	};

	const handleSubmitGuess = (selectedItems) => {
		// socket.disconnect();

		if (socket && socket.connected) {
			socket.emit("submitGuess", lobbyId, selectedItems);
		} else {
			console.log("disconnected branch");
			const newSocket = io(process.env.REACT_APP_BACKEND_URL);
			newSocket.emit(
				"joinGame",
				lobbyId,
				name,
				requiresLogin ? spotifyId : name
			);
			setSocket(newSocket);
		}
	};

	function goHome() {
		navigate("/" + modeRoute);
	}

	// todo there is bug with tabbing out and combing back phone
	return (
		<PhoneDisplayPanel>
			{/* <ShareLink /> */}
			{currentSong ? (
				<SpotifyPlayer
					uri={currentSong.uri}
					showPlayButton={currentScreen === "guess"}
					transitionOut={transitioning}
				/>
			) : (
				<div style={{ height: "56px" }}></div>
			)}
			{token && (
				<DevicesDropdown
					token={token}
					spotifyDevice={spotifyDevice}
					onDeviceChange={handleDeviceChange}
					currentSong={currentSong}
				/>
			)}

			{currentScreen === "guess" ? (
				<>
					<GuessSongScreen
						song={currentSong.name}
						artist={currentSong.artists}
						image={currentSong.albumImage}
						uri={currentSong.uri}
						names={options}
						socket={socket}
						correctAnswer={correctAnswer}
						handleSubmit={handleSubmitGuess}
						lobbyId={lobbyId}
						transitionOut={transitioning}
					/>
				</>
			) : currentScreen === "scoreboard" ? (
				<CurrentScoreScreen
					currentRound={currentRound}
					totalRounds={numRounds}
					scoreboard={scoreboard}
					goNext={goNext}
					transitionOut={transitioning}
				/>
			) : (
				currentScreen === "lobby" && (
					<Lobby
						lobbyId={lobbyId}
						gameData={gameData}
						isGameLeader={isGameLeader}
						handleStartGame={handleStartGame}
						styles={styles}
						readyToStart={readyToStart}
					/>
				)
			)}
			{/* <SongPreviewPlayButton
				currentSong={currentSong}
				getTrackIdFromUri={getTrackIdFromUri}
			/> */}

			<button
				onClick={() => goHome()}
				style={{
					color: "white",
					background: "none",
					border: "0px",
					position: "absolute",
					bottom: currentScreen === "lobby" ? "60px" : "0px",
					// position: "fixed",
					// marginTop: "40px",
					padding: "20px",
					cursor: "pointer",
				}}
			>
				Leave Game
			</button>
		</PhoneDisplayPanel>
	);
};

export default GamePage;

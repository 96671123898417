export async function fetchRecommendations(spotifyToken, options) {
	const apiUrl = "https://api.spotify.com/v1/recommendations";

	const queryParams = new URLSearchParams();

	for (const key in options) {
		if (options[key] !== undefined) {
			if (Array.isArray(options[key])) {
				queryParams.append(key, options[key].join(","));
			} else {
				queryParams.append(key, options[key]);
			}
		}
	}

	const headers = {
		Authorization: `Bearer ${spotifyToken}`,
	};

	try {
		const response = await fetch(`${apiUrl}?${queryParams.toString()}`, {
			method: "GET",
			headers: headers,
		});
		return await response.json();
	} catch (error) {
		return [];
	}
}

export async function fetchAudioFeatures(spotifyToken, trackId) {
	try {
		const response = await fetch(
			`https://api.spotify.com/v1/audio-features/${trackId}`,
			{
				headers: {
					Authorization: `Bearer ${spotifyToken}`,
				},
			}
		);
		const data = await response.json();
		return data;
	} catch (error) {
		console.error("Error fetching audio features:", error);
	}
}

export async function setVolume(spotifyToken, volumePercent) {
	if (volumePercent < 0 || volumePercent > 100) {
		console.error("Volume must be between 0 and 100");
		return;
	}

	try {
		const response = await fetch(
			`https://api.spotify.com/v1/me/player/volume?volume_percent=${volumePercent}`,
			{
				method: "PUT",
				headers: {
					Authorization: `Bearer ${spotifyToken}`,
				},
			}
		);

		if (!response.ok) {
			console.error("Error setting volume:", response.statusText);
			return;
		}

		console.log("Volume set successfully");
	} catch (error) {
		console.error("Error setting volume:", error);
	}
}

//TODO CATCH ERRORS
export async function fetchSingleSong(spotifyToken, index) {
	const song_list = await fetchSavedSongs(spotifyToken, 1, index);
	const song = song_list[0];

	const { name, artists, uri, album } = song.track;

	return {
		name: name,
		artists: artists.map((artist) => artist.name).join(", "), // Join multiple artists with a comma
		uri: uri,
		albumImage: album.images.length > 0 ? album.images[0].url : null, // Use the first image as an example
	};
}

export async function fetchSavedSongs(spotifyToken, limit = 50, offset = 0) {
	const apiUrl =
		"https://api.spotify.com/v1/me/tracks?limit=" + limit + "&offset=" + offset;

	const headers = {
		Authorization: `Bearer ${spotifyToken}`,
	};

	try {
		const response = await fetch(apiUrl, {
			method: "GET",
			headers: headers,
		});

		if (!response.ok) {
			console.error("Error fetching saved songs:", response.statusText);
			return [];
		}

		const data = await response.json();
		// return data;
		return data.items; //.map(item => item.track);
	} catch (error) {
		console.error("Error fetching saved songs:", error);
		return [];
	}
}

async function isUserLibraryLongerThanN(token, n) {
	try {
		const songs = await fetchSavedSongs(token, 1, n);

		return songs.length > 0;
	} catch (error) {
		return false;
	}
}

export async function findUserLibraryLength(token, averageLength = 1000) {
	let low = 0;
	let high = averageLength;

	while (await isUserLibraryLongerThanN(token, high)) {
		high *= 2;
	}

	while (low < high) {
		const mid = Math.floor((low + high) / 2);

		if (await isUserLibraryLongerThanN(token, mid)) {
			low = mid + 1;
		} else {
			high = mid;
		}
	}

	return low;
}

export async function checkIfSongIsSaved(spotifyToken, songId) {
	const apiUrl = `https://api.spotify.com/v1/me/tracks/contains?ids=${songId}`;

	const headers = {
		Authorization: `Bearer ${spotifyToken}`,
	};

	try {
		const response = await fetch(apiUrl, {
			method: "GET",
			headers: headers,
		});

		if (!response.ok) {
			console.error("Error checking if song is saved:", response.statusText);
			return false; // Return false in case of an error.
		}

		const data = await response.json();
		// The response will be an array of boolean values, one for each song ID provided.
		// In this case, it should be an array with a single boolean value.
		return data[0];
	} catch (error) {
		console.error("Error checking if song is saved:", error);
		return false; // Return false in case of an error.
	}
}

export async function getWhichSongIndicesAreLiked(token, songUris) {
	if (token) {
		const liked_indices = [];
		console.log(songUris);
		for (let i = 0; i < songUris.length; i++) {
			const isSaved = checkIfSongIsSaved(token, songUris[i]);
			if (isSaved) {
				liked_indices.push(i);
			}
		}
		return liked_indices;
	} else {
		const playlistSongUris = JSON.parse(
			window.localStorage.getItem("songs")
		).map((song) => song.uri);
		const liked_indices = [];

		for (let i = 0; i < songUris.length; i++) {
			if (playlistSongUris.includes(songUris[i])) {
				liked_indices.push(i);
			}
		}
		return liked_indices;
	}
}

export async function getUserProfile(spotifyToken) {
	console.log(spotifyToken);
	const apiUrl = "https://api.spotify.com/v1/me";

	const headers = {
		Authorization: `Bearer ${spotifyToken}`,
	};

	try {
		const response = await fetch(apiUrl, {
			method: "GET",
			headers: headers,
		});

		if (!response.ok) {
			throw new Error(`Error fetching user profile: ${response.statusText}`);
		}

		const data = await response.json();
		return data;
	} catch (error) {
		console.error("Error fetching user profile:", error);
		throw error; // Rethrow the error to handle it in the calling function.
	}
}

export async function playSpecificSongOnDevice(
	spotifyToken,
	trackUri,
	deviceId,
	startPositionMs = 0
) {
	const apiUrl = `https://api.spotify.com/v1/me/player/play?device_id=${deviceId}`;

	const body = {
		uris: [trackUri],
		position_ms: startPositionMs, // Set the starting position in milliseconds
	};

	const headers = {
		Authorization: `Bearer ${spotifyToken}`,
		"Content-Type": "application/json",
	};

	try {
		const response = await fetch(apiUrl, {
			method: "PUT",
			headers: headers,
			body: JSON.stringify(body),
		});

		if (!response.ok) {
			console.error("Error playing the specific song:", response.statusText);
			return;
		}

		console.log("Playing the specific song successfully on device:", deviceId);
	} catch (error) {
		console.error("Error playing the specific song:", error);
	}
}

export async function stopPlayback(spotifyToken, deviceId) {
	const apiUrl = `https://api.spotify.com/v1/me/player/pause?device_id=${deviceId}`;

	const headers = {
		Authorization: `Bearer ${spotifyToken}`,
	};

	try {
		const response = await fetch(apiUrl, {
			method: "PUT",
			headers: headers,
		});

		if (!response.ok) {
			console.error("Error stopping playback:", response.statusText);
			return;
		}

		console.log("Playback stopped successfully on device:", deviceId);
	} catch (error) {
		console.error("Error stopping playback:", error);
	}
}

export async function getSpotifyDevices(spotifyToken) {
	console.log(spotifyToken);
	const apiUrl = "https://api.spotify.com/v1/me/player/devices";

	const headers = {
		Authorization: `Bearer ${spotifyToken}`,
	};

	try {
		const response = await fetch(apiUrl, {
			method: "GET",
			headers: headers,
		});

		if (!response.ok) {
			console.error("Error fetching Spotify devices:", response.statusText);
			return null;
		}

		const data = await response.json();
		return data;
	} catch (error) {
		console.error("Error fetching Spotify devices:", error);
		return null;
	}
}

export class SpotifyShuffler {
	constructor(libraryLength) {
		this.libraryLength = libraryLength;
	}

	async getSong(spotifyToken) {
		if (this.libraryLength <= 0) {
			console.error("No songs in the library.");
			return null;
		}

		const randomIndex = Math.floor(Math.random() * this.libraryLength);
		const limit = 1; // We want to fetch just one song
		const offset = randomIndex;

		try {
			const songs = await fetchSavedSongs(spotifyToken, limit, offset);
			if (songs.length === 0) {
				console.error("No songs fetched from the library.");
				return null;
			}

			return songs[0]; // Return the randomly selected song
		} catch (error) {
			console.error("Error while fetching a random song:", error);
			return null;
		}
	}
}

// export async function checkIfSongsSaved(song)

export async function fetchNSongs(token, loggedInWithPlaylist, librarySize, n) {
	if (token) {
		const songs = [];

		for (let i = 0; i < n; i++) {
			const randomIndex = Math.floor(Math.random() * librarySize);
			let song;
			let success = false;

			while (!success) {
				try {
					song = await fetchSingleSong(token, randomIndex);
					songs.push(song);
					success = true; // Set success to true to break the loop if no error is thrown
				} catch (error) {
					// Handle the error if needed, or you can simply continue the loop
					console.error(
						"An error occurred while fetching the single song:",
						error
					);
				}
			}
		}
		return songs;
	} else {
		const playlistSongs = JSON.parse(window.localStorage.getItem("songs"));
		const nSongs = [];
		const usedIndices = [];
		if (librarySize < n) {
			throw new Error("Not enough songs in the library"); // Provide a proper error message
		}
		for (let i = 0; i < n; i++) {
			const randomIndex = Math.floor(Math.random() * librarySize);

			if (usedIndices.includes(randomIndex)) {
				i -= 1;
				continue;
			}
			usedIndices.push(randomIndex);

			const { name, artists, uri, albumImage } = playlistSongs[randomIndex];

			nSongs.push({
				name: name,
				artists: artists, // Join multiple artists with a comma
				uri: uri,
				albumImage: albumImage, // Use the first image as an example
			});
		}
		return nSongs;
	}
}

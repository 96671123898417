import './NameList.css';
import styles from '../../styles/MainMenu.module.css'

const NameList = ({names}) => {
  return (
      <div className={`name-list ${styles.textColor}`}>
        {names.map((name, index) => (
            <div key={index} className="name-item">
              {name}
            </div>
        ))}
      </div>
  )
}

export default NameList;
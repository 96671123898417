import styles from "../../styles/Popup.module.css";

function PopUp({
	isOpen,
	content,
	onClose,
	closeText = "Close",
	closeLink = "",
}) {
	if (!isOpen) return null;
	return (
		<div className={styles.popupOverlay}>
			<div
				style={{ width: "85%", maxWidth: "400px" }}
				className={styles.popupContent}
			>
				{content}
				{closeLink && (
					<>
						<img
							style={{ width: "100%", maxWidth: "350px", marginBottom: "20px" }}
							src="/images/addToOtherPlaylist3.gif"
							alt="Add to Other Playlist"
						/>
					</>
				)}
				<button
					style={{
						background: "#3e3e3e",
						color: "lightgray",
						outline: "none",
						border: "none",
					}}
					onClick={onClose}
				>
					{closeText}
				</button>
			</div>
		</div>
	);
}

export default PopUp;

import { useEffect, useState } from 'react';
import { getSpotifyDevices } from '../../services/SpotifyApi';

function DevicesDropdown({ token, onDeviceChange, spotifyDevice, currentSong}) {
  const noDevice = {id: '-1', name: "No Playback Device"};
  const [devices, setDevices] = useState([noDevice]);
  const [refreshDevices, setRefreshDevices] = useState(false);

  useEffect(() => {
    console.log("in use effect")
    const newDevices = handleDropdownClick();
  }, [token, refreshDevices]);

  // Handle dropdown click
  const handleDropdownClick = () => {
    if (token) {
      getSpotifyDevices(token).then((data) => {
        if (data) {
          const retrievedDevices = data.devices;
          if (retrievedDevices && retrievedDevices.length > 0) {
            retrievedDevices.unshift(noDevice)
            setDevices(retrievedDevices);
            console.log("found devices")
            console.log(retrievedDevices);
            console.log(spotifyDevice);
            if (!spotifyDevice || spotifyDevice.id !== retrievedDevices[retrievedDevices.length - 1].id) {
              onDeviceChange(retrievedDevices[retrievedDevices.length - 1])
            }
            return retrievedDevices;
            // todo: set the selected device in the dropdown to the last one in the devices list
          } else {
            setDevices([noDevice, {id: '-2', name: "Launch Spotify"}]);
            console.log("no devices found")
            console.log(data);
            console.log(token);
          }
        }
      });
    }
    return null;
  };

  const spotifyUrl = 'spotify://';

  // Function to open Spotify or redirect to web player
  const openSpotify = () => {
    // Attempt to open the Spotify app
    let url;
    if (currentSong) {
      url = currentSong.uri;
      console.log(url);
    } else {
      url = spotifyUrl
    }

    window.location.href = url;
  //   todo: if there is a current song

    setTimeout(function() {
      console.log("attempting refresh")
      setRefreshDevices(true);
    }, 6000); // 1000 milliseconds = 1 second

  };

  // Handle dropdown selection
  const handleDeviceChange = (event) => {
    const selectedDeviceId = event.target.value;
    if (selectedDeviceId === '-2') {
      openSpotify()
    }
    console.log(devices)
    const selectedDevice = devices.find((device) => device.id === selectedDeviceId);
    console.log("selected")
    console.log(selectedDevice)
    onDeviceChange(selectedDevice); // Call the callback to update the parent state
  };

  return (
      <div style={{marginTop: '10px', paddingBottom: '5px', width: '100%'}}>
        <select
            value={spotifyDevice ? spotifyDevice.id : ''}
            onClick={handleDropdownClick}
            onChange={handleDeviceChange}
            style={{paddingTop: '10px', paddingBottom: '10px', width: '100%', background: '#222222', color: "white", border: '0px'}}
        >
          {devices.map((device) => (
              <option key={device.id} value={device.id}>
                {device.name}
              </option>
          ))}
        </select>
      </div>
  );
}

export default DevicesDropdown;

import { useEffect, useState } from "react";
import { findUserLibraryLength, getUserProfile } from "./SpotifyApi";
import { backEndAddress } from "../config";

const CLIENT_ID = "a3108e9dd2ba4799aa835a934b9185bb";

const AUTH_ENDPOINT = "https://accounts.spotify.com/authorize";
const RESPONSE_TYPE = "token";
const SCOPE =
	"user-modify-playback-state user-library-read user-read-playback-state user-read-currently-playing";
// Include the user-library-read scope

export function useSpotifyAuth(redirect_uri, triggerPopUp = null) {
	const [token, setToken] = useState(() => {
		let savedToken = window.localStorage.getItem("token");
		let savedTokenExpiry = window.localStorage.getItem("tokenExpiry");

		//check that token isn't expired
		if (
			!savedToken ||
			(savedTokenExpiry && Date.now() >= parseInt(savedTokenExpiry))
		) {
			return "";
		} else {
			return savedToken;
		}
	});
	const [loggedInWithPlaylist, setLoggedInWithPlaylist] = useState(() => {
		try {
			return JSON.parse(window.localStorage.getItem("songs")) !== null;
		} catch {
			return false;
		}
	});

	const [librarySize, setLibrarySize] = useState(
		window.localStorage.getItem("librarySize")
	);
	const [userId, setUserId] = useState(
		// Initialize user ID state
		window.localStorage.getItem("userId")
	);

	//do error pop ups
	//also local storage bad
	async function loginWithPlaylist(playlist_id, isApple = false) {
		let response;
		if (isApple) {
			response = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/loginWithApplePlaylist?playlist_link=${playlist_id}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
		} else {
			response = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/loginWithPlaylist?playlist_id=${playlist_id}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
		}

		if (!response.ok) {
			triggerPopUp(response.statusText);
			console.error("Server responded with an error:", response.statusText);
			return;
		}

		const data = await response.json();
		console.log("Response from server:", data);

		if (data.error) {
			triggerPopUp(`Error: ${data.error}`);
			console.error("Server responded with an error:", data.error);
			return;
		}

		if (data.songs && data.songs.length > 9) {
			// Save songs in local storage
			try {
				localStorage.setItem("songs", JSON.stringify(data.songs));
				setLoggedInWithPlaylist(true);
				localStorage.setItem("userId", data.playerId);
				window.localStorage.setItem("librarySize", data.songs.length);
				setLibrarySize(data.songs.length);

				setUserId(data.playerId);
			} catch (e) {
				throw new Error("Playlist Too Long. Choose one with < 3000 songs");
			}
		} else {
			triggerPopUp(`Error: Need at least 10 songs in playlist`);
			return;
		}
	}

	useEffect(() => {
		if (loggedInWithPlaylist) {
			return;
		}
		const hash = window.location.hash;
		let savedToken = window.localStorage.getItem("token");
		let savedTokenExpiry = window.localStorage.getItem("tokenExpiry");

		if (
			!savedToken ||
			(savedTokenExpiry && Date.now() >= parseInt(savedTokenExpiry))
		) {
			// Token is expired or not available, initiate the login process
			console.log("Token is expired or not available. Initiating login...");
		} else {
			// Token is still valid, use it
			console.log("Using existing token:", savedToken);
			setToken(savedToken);
		}

		if (hash) {
			// Handle new tokens from the Spotify redirect
			const newToken = hash
				.substring(1)
				.split("&")
				.find((elem) => elem.startsWith("access_token"))
				.split("=")[1];

			if (newToken) {
				const expiresIn = parseInt(
					hash
						.substring(1)
						.split("&")
						.find((elem) => elem.startsWith("expires_in"))
						.split("=")[1]
				);

				// Save the new token and its expiration time
				console.log("Received new token:", newToken);
				console.log("Token expiration in seconds:", expiresIn);
				window.localStorage.setItem("token", newToken);
				window.localStorage.setItem(
					"tokenExpiry",
					Date.now() + expiresIn * 1000
				);
				setToken(newToken);

				// Fetch user profile to get user ID
				async function fetchUserProfile() {
					try {
						const userProfile = await getUserProfile(newToken);
						const user_id = userProfile.id;

						// Save the user ID to local storage
						window.localStorage.setItem("userId", user_id);
						setUserId(user_id);
						console.log(user_id);

						// Fetch library size when getting a new token
						const result = await findUserLibraryLength(newToken);
						setLibrarySize(result);
						// Save librarySize in localStorage
						window.localStorage.setItem("librarySize", result);
						console.log(result);
					} catch (error) {
						// Handle any errors here
						console.error(error);
					}
				}
				fetchUserProfile();

				// Clear the URL hash
				window.location.hash = "";
			}
		}
	}, [loggedInWithPlaylist]);

	const getLoginUrl = () => {
		return `${AUTH_ENDPOINT}?client_id=${CLIENT_ID}&redirect_uri=${redirect_uri}&response_type=${RESPONSE_TYPE}&scope=${encodeURIComponent(
			SCOPE
		)}`;
	};

	const logout = () => {
		setToken("");
		setLoggedInWithPlaylist(false);
		window.localStorage.removeItem("songs");
		window.localStorage.removeItem("token");
		window.localStorage.removeItem("tokenExpiry");
		console.log("Logged out. Token and expiration removed.");
	};

	return {
		token,
		loginWithPlaylist,
		loggedInWithPlaylist,
		loginUrl: getLoginUrl(),
		logout,
		librarySize,
		userId,
	};
}

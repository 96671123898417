import React from "react";
import "../../styles/SpotifyLoginButton.css"; // Create a CSS file for styling
import { Nav } from "react-bootstrap";

const LogoutButton = ({ logout }) => {
	return (
		<Nav.Link className={"white-button"} onClick={logout}>
			Use Different Playlist
		</Nav.Link>
	);
};

export default LogoutButton;

import { useState } from "react";
import HowToPlay from "./HowToPlay";

export default function InfoButtons({ selectedGameMode }) {
	const [howExpanded, setHowExpanded] = useState(false);
	const [noPlaylistExpanded, setNoPlaylistExpanded] = useState(false);

	function setExpanded(value, id) {
		switch (id) {
			case 0:
				setNoPlaylistExpanded(!value);
				setHowExpanded(value);
				break;
			case 1:
				setNoPlaylistExpanded(value);
				setHowExpanded(!value);
				break;
			default:
				break;
		}
	}

	return (
		<div
			style={{
				display: "flex",
				flexDirection: howExpanded
					? "column"
					: noPlaylistExpanded
					? "column-reverse"
					: "row",
				alignItems: "center",
				justifyContent:
					selectedGameMode === "asongus" ? "space-between" : "center",
				width: "100%",
				// transition: "flexDirection 0.1s ease",
			}}
		>
			{!noPlaylistExpanded && (
				<HowToPlay
					selectedGameMode={selectedGameMode}
					setExpanded={(value) => setExpanded(value, 0)}
					expanded={howExpanded}
				/>
			)}

			{selectedGameMode === "asongus" && !howExpanded && (
				<HowToPlay
					setExpanded={(value) => setExpanded(value, 1)}
					expanded={noPlaylistExpanded}
					title={"No Good Playlists?"}
					selectedGameMode={"noPlaylist"}
				/>
			)}
		</div>
	);
}

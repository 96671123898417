import React from "react";
import BackToLobbyButton from "./BackToLobbyButton";
import GamePinDisplay from "./GamePinDisplay";
import NameList from "./NameList";
import ShareLink from "./ShareLink";

function Lobby({
	lobbyId,
	gameData,
	isGameLeader,
	handleStartGame,
	styles,
	readyToStart,
}) {
	return (
		<>
			{/*<BackToLobbyButton />*/}
			{/* <div
				style={{
					width: "100%",
					position: "relative",
					// paddingTop: "10px",
					top: "69px",
					color: "white",
				}}
			>
				<ShareLink />
			</div> */}

			<div
				style={{
					display: "grid",
					gridTemplateColumns: "1fr 1fr 1fr", // Creates three equal columns
					alignItems: "flex-end", // Centers items vertically
					width: "100%",
					// background: "green",
				}}
			>
				<div style={{ textAlign: "left" }}>
					<ShareLink />
				</div>
				<div style={{ textAlign: "center" }}>
					<GamePinDisplay gamePin={lobbyId} />
				</div>
				<div style={{ textAlign: "right" }}>
					{/* Another component or leave empty */}
				</div>
			</div>

			{gameData && (
				<>
					<NameList
						names={gameData.players.map((player) => player.playerName)}
					/>
					{isGameLeader ? (
						<>
							{readyToStart ? (
								<button
									onClick={handleStartGame}
									className={`${styles.join} ${styles.button} slide-in`}
									key="start-game-button"
								>
									START GAME
								</button>
							) : (
								<div style={{ color: "white" }}></div>
							)}
						</>
					) : (
						<div className={styles.textColor}>
							Waiting for {gameData.players && gameData.players[0].playerName}{" "}
							to start...
						</div>
					)}
				</>
			)}
		</>
	);
}

export default Lobby;

import styles from "../../styles/MainMenu.module.css";
import ShareLink from "./ShareLink";

const GamePinDisplay = ({ gamePin }) => {
	const gamePinDisplay = {};
	return (
		<div className={`${styles.gamePinDisplay} ${styles.textColor}`}>
			{/*<h2>Game PIN</h2>*/}Game PIN
			<h1 className={styles.gamePin}>{gamePin}</h1>
		</div>
	);
};

export default GamePinDisplay;

import React from "react";
import LandingPage from "./Screens/MainMenu/MainMenu";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import GamePage from "./Screens/Lobby/GamePage";
import EnterName from "./Screens/MainMenu/EnterName";
import SpotifyPlayer from "./components/SpotifyPlayer";
import MainLandingPage from "./Screens/MainLandingPage";
import GuessTheCountry from "./Screens/GuessTheCountry";

import AdvertiserDashboard from "./Screens/AdvertiserDashboard";

function App() {
	return (
		<div>
			<Router>
				<Routes>
					<Route path="/" element={<MainLandingPage />} />
					<Route
						path="/asongus/"
						element={
							<LandingPage requiresLogin={true} selectedGameMode="asongus" />
						}
					/>
					<Route
						path="/advertiser-dashboard/"
						element={<AdvertiserDashboard />}
					/>
					<Route
						path="/charts-game/"
						element={
							<LandingPage
								requiresLogin={false}
								selectedGameMode="charts-game"
							/>
						}
					/>
					<Route
						path="/asongus/lobby/:lobbyId/"
						element={<GamePage modeRoute={"asongus"} requiresLogin={true} />}
					/>
					<Route
						path="/charts-game/lobby/:lobbyId/"
						element={
							<GamePage modeRoute={"charts-game"} requiresLogin={false} />
						}
					/>
					<Route path="/asongus/enter-name" element={<EnterName />} />
					<Route
						path="/charts-game/enter-name"
						element={<EnterName modeRoute="charts-game" />}
					/>
				</Routes>
			</Router>
		</div>
	);
}

export default App;

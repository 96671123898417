import React from "react";
import "../styles/Loader.css";

const Loader = ({ color = "darkGray" }) => {
	return (
		<div className="loaderContainer">
			<div className={`loader ` + color}></div>
		</div>
	);
};

export default Loader;

import { useRef } from "react";
import styles from "../../styles/MainMenu.module.css";

const InputField = ({
	placeholder,
	shake,
	value,
	onNameChange,
	transitioningForward = false,
	goingBack = false,
}) => {
	return (
		<input
			type="text"
			placeholder={placeholder}
			value={value}
			onChange={onNameChange}
			className={`${styles.input} ${shake ? styles.shake : ""} 
                        ${transitioningForward ? styles.moveOffScreen : ""}
                        ${goingBack && styles.moveBackOnScreen}`}
		/>
	);
};

export default InputField;

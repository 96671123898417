import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpFromBracket } from "@fortawesome/free-solid-svg-icons";

export default function ShareLink({}) {
	const [copied, setCopied] = useState(false);

	const handleCopyLink = () => {
		const url = window.location.href;
		if (navigator.clipboard && navigator.clipboard.writeText) {
			navigator.clipboard.writeText(url).then(
				() => {
					setCopied(true);
					setTimeout(() => setCopied(false), 2000); // Reset the copied state after 2 seconds
				},
				() => {
					console.error("Failed to copy the link.");
				}
			);
		} else {
			console.error(
				"Clipboard API not supported or not available in this context."
			);
		}
	};

	return (
		<div
			onClick={handleCopyLink}
			style={{
				// background: "yellow",
				display: "flex",
				flexDirection: "column",
				alignItems: "flex-start",
				color: "white",
				// fontWeight: "300",
				fontSize: "15px",
				cursor: "pointer",
			}}
		>
			<>Invite Friends</>
			{/* fix this */}
			{copied && (
				<div style={{ background: "white", color: "black", position: "fixed" }}>
					Game link copied!
				</div>
			)}
			<button
				style={{
					color: "white",
					background: "none",
					border: "none",
					fontSize: "26px",
					padding: "2px",
					// width: "20%",
					// fontWeight: "lighter",
					marginTop: "3px",
					// background: "red",
					// alignSelf: "flex-start",
					cursor: "pointer",
				}}
			>
				<FontAwesomeIcon icon={faArrowUpFromBracket} />
			</button>
		</div>
	);
}

import React from "react";
import "../../App.css";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NameGuess = ({ playerScore }) => {
	return (
		<div
			style={{
				position: "absolute",
				left: "50px",
				display: "flex",
				flexDirection: "column",
			}}
		>
			<div style={{ fontWeight: "bold" }}>{playerScore.playerName}</div>
			<div style={{ display: "flex", flexDirection: "row" }}>
				{playerScore.mostRecentCorrectedGuesses &&
					playerScore.mostRecentCorrectedGuesses.map((guess, index) => (
						<div
							style={{
								marginRight: "10px",
								display: "flex",
								flexDirection: "row",
							}}
							key={index}
						>
							<div>
								{guess.correct ? (
									<div
										style={{
											color: "green",
											display: "flex",
											flexDirection: "row",
										}}
									>
										<FontAwesomeIcon icon={faCheck} />
										<div>{guess.nameGuessed}</div>
									</div>
								) : (
									<div
										style={{
											color: "red",
											display: "flex",
											flexDirection: "row",
										}}
									>
										<FontAwesomeIcon icon={faTimes} />
										<div>{guess.nameGuessed}</div>
									</div>
								)}
							</div>
						</div>
					))}
			</div>
		</div>
	);
};

const ScoreCard = ({ playerScore, index }) => {
	const scoreCardStyle = {
		display: "flex",
		flexDirection: "row",
		position: "relative",
		backgroundColor: "white",
		width: "100%",
		paddingTop: "20px",
		paddingBottom: "20px",
		borderRadius: "10px",
		margin: "5px",
	};

	return (
		<div style={scoreCardStyle}>
			<div style={{ fontWeight: "bold", marginLeft: "20px" }}>{index + 1}</div>
			<NameGuess playerScore={playerScore} />
			<div
				style={{
					position: "absolute",
					right: "20px",
				}}
			>
				{playerScore.score}
			</div>
		</div>
	);
};

const ScoreContainer = ({ scoreboard, currentRound, transitionOut }) => {
	const containerStyle = {
		display: "flex",
		width: "100%",
		flexDirection: "column",
		alignItems: "center",
	};

	if (!scoreboard) {
		return null; // Return null if the scoreboard is not defined
	}

	const currentPlayerScores = scoreboard.playerScores.filter(
		(pS) => pS.mostRecentRoundUpdated === currentRound
	);

	return (
		<div
			style={containerStyle}
			className={transitionOut ? "fade-out" : "slide-down"}
		>
			{scoreboard
				? currentPlayerScores.map((playerScore, index) => (
						<ScoreCard playerScore={playerScore} index={index} />
				  ))
				: ""}
		</div>
	);
};

const CurrentScoreScreen = ({
	currentRound,
	totalRounds,
	scoreboard,
	goNext,
	transitionOut,
}) => {
	return (
		<div
			style={{
				width: "100%",
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-evenly",
				alignItems: "center",
				position: "relative",
			}}
		>
			<h1
				className={transitionOut ? "text slide-up-out" : "text slide-down"}
				style={{ position: "relative" }}
			>
				{currentRound + 1 === totalRounds ? (
					<div className="fade-in-scale">
						{scoreboard && scoreboard.playerScores[0].playerName} wins!
					</div>
				) : (
					<>
						Round {currentRound + 1} of {totalRounds}
					</>
				)}
			</h1>
			<div
				style={{
					width: "100%",
				}}
			>
				<div
					// className="slide-down"
					style={{
						width: "100%",
					}}
				>
					<ScoreContainer
						scoreboard={scoreboard}
						currentRound={currentRound}
						transitionOut={transitionOut}
					/>
				</div>
			</div>

			{/*<button onClick={goNext}>Next</button>*/}
		</div>
	);
};

export default CurrentScoreScreen;
